import React from 'react';
import PropTypes from 'prop-types';

import getAnchor from './../../utils/anchor';
import Hanchor from './H-anchor';
import * as styles from './H.module.css';

const H3 = ({ children }) => {
  const anchor = getAnchor(children);
  const title = `Direct link to "${children}" section`;

  return (
    <h3
      id={anchor}
      className={[
        'container',
        'subsectionTitle',
        styles.header,
        styles.header3,
      ].join(' ')}
    >
      <Hanchor anchor={anchor} title={title} />
      {children}
    </h3>
  );
};

H3.propTypes = {
  children: PropTypes.any,
};

export default H3;
